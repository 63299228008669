import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// LIFE@AYANA Carousel images
import Fun1 from "../assets/img/life_at_ayana_images/1.png";
import Fun2 from "../assets/img/life_at_ayana_images/2.png";
import Fun3 from "../assets/img/life_at_ayana_images/3.png";
import Fun4 from "../assets/img/life_at_ayana_images/4.png";
import Fun5 from "../assets/img/life_at_ayana_images/5.png";
import Fun6 from "../assets/img/life_at_ayana_images/6.png";
import Fun7 from "../assets/img/life_at_ayana_images/7.jpg";
import Fun8 from "../assets/img/life_at_ayana_images/8.jpg";
import Fun9 from "../assets/img/life_at_ayana_images/9.jpg";
import Fun10 from "../assets/img/life_at_ayana_images/10.jpg";
import Fun11 from "../assets/img/life_at_ayana_images/11.jpg";
import Fun12 from "../assets/img/life_at_ayana_images/12.jpg";
import Fun13 from "../assets/img/life_at_ayana_images/13.jpg";
import Fun14 from "../assets/img/life_at_ayana_images/14.jpg";
import Fun15 from "../assets/img/life_at_ayana_images/15.jpg";

// LIFE@SITE carousel images
import PSite01 from "../assets/img/life_at_project_site_images/PSite01.jpg";
import PSite02 from "../assets/img/life_at_project_site_images/PSite02.jpg";
import PSite03 from "../assets/img/life_at_project_site_images/PSite03.jpg";
import PSite04 from "../assets/img/life_at_project_site_images/PSite04.jpg";
import PSite05 from "../assets/img/life_at_project_site_images/Frame4.png";
import PSite06 from "../assets/img/life_at_project_site_images/Frame5.png";

// REWARDS AND RECOGNITION IMAGES
import Awards01 from "../assets/img/rewards_and_recognition/1.jpg";
import Awards02 from "../assets/img/rewards_and_recognition/2.jpg";
import Awards03 from "../assets/img/rewards_and_recognition/3.jpg";
import Awards04 from "../assets/img/rewards_and_recognition/4.jpg";
import Awards05 from "../assets/img/rewards_and_recognition/5.jpg";
import Awards06 from "../assets/img/rewards_and_recognition/6.jpg";
import Awards07 from "../assets/img/rewards_and_recognition/7.jpg";
import Awards08 from "../assets/img/rewards_and_recognition/8.jpg";
import Awards09 from "../assets/img/rewards_and_recognition/9.jpg";
import Awards10 from "../assets/img/rewards_and_recognition/10.jpg";
import Awards11 from "../assets/img/rewards_and_recognition/11.jpg";
import Awards12 from "../assets/img/rewards_and_recognition/12.jpg";
import Awards13 from "../assets/img/rewards_and_recognition/13.jpg";
import Awards14 from "../assets/img/rewards_and_recognition/14.jpg";
import Awards15 from "../assets/img/rewards_and_recognition/15.jpg";
import Awards16 from "../assets/img/rewards_and_recognition/16.jpg";
import Awards17 from "../assets/img/rewards_and_recognition/17.jpg";
import Awards18 from "../assets/img/rewards_and_recognition/18.jpg";
import Awards19 from "../assets/img/rewards_and_recognition/19.jpg";
import Awards20 from "../assets/img/rewards_and_recognition/20.jpg";
import Awards21 from "../assets/img/rewards_and_recognition/21.jpg";
import Awards22 from "../assets/img/rewards_and_recognition/22.jpg";
import Awards23 from "../assets/img/rewards_and_recognition/23.jpg";

// RECOGNITION THAT MATTERS (AWARDS)
import CoAwards01 from "../assets/img/recognitions/CoAwards01.jpg";
import Ayana_Renewable_Engg_team_Award from "../assets/img/recognitions/Ayana_Renewable_Engg_team_Award.jpg";
import Ayana_Renewable_MA_Award from "../assets/img/recognitions/Ayana_Renewable_MA_Award.jpg";
import Ayana_Renewable_Saravanan_Award from "../assets/img/recognitions/Ayana_Renewable_Saravanan_Award.jpg";
import CoAwards08 from "../assets/img/recognitions/CoAwards08.jpg";
import CoAwards10 from "../assets/img/recognitions/CoAwards10.jpg";
import CoAwards11 from "../assets/img/recognitions/coaward11.JPG";
import iip from "../assets/img/recognitions/iip.png";
import iip1 from "../assets/img/recognitions/iip1.png";
import Ayana_Power from "../assets/img/recognitions/Ayana_Power.JPG";
import image13 from "../assets/img/recognitions/image13.JPG";
import image18 from "../assets/img/recognitions/image18.jpg";
import image19 from "../assets/img/recognitions/image19.jpeg";
import image12 from "../assets/img/recognitions/images12.png";
import bsc_merit_certificate from "../assets/img/recognitions/bsc_merit_certificate.jpg";
import world_sustainability_award from "../assets/img/recognitions/world_sustainability_award.jpeg";
import ayana_gptw_award from "../assets/img/recognitions/ayana_gptw_certificate.png";
import national_employer_brand_2019 from "../assets/img/recognitions/national_employer_brand_2019.png";

const lifeAtAyanaImages = [
  Fun15,
  Fun14,
  Fun13,
  Fun12,
  Fun11,
  Fun10,
  Fun9,
  Fun8,
  Fun7,
  Fun6,
  Fun5,
  Fun4,
  Fun3,
  Fun2,
  Fun1,
];

const lifeAtSiteImages = [PSite01, PSite02, PSite03, PSite04, PSite05, PSite06];

const rewardsImages = [
  Awards23,
  Awards22,
  Awards21,
  Awards20,
  Awards19,
  Awards18,
  Awards17,
  Awards16,
  Awards15,
  Awards14,
  Awards13,
  Awards12,
  Awards11,
  Awards10,
  Awards09,
  Awards08,
  Awards07,
  Awards06,
  Awards05,
  Awards04,
  Awards03,
  Awards02,
  Awards01,
];

const recognitionImages = [
  bsc_merit_certificate,
  world_sustainability_award,
  ayana_gptw_award,
  national_employer_brand_2019,
  Ayana_Power,
  image13,
  image18,
  image12,
  image19,
  iip,
  iip1,
  CoAwards01,
  Ayana_Renewable_MA_Award,
  Ayana_Renewable_Engg_team_Award,
  Ayana_Renewable_Saravanan_Award,
  CoAwards08,
  CoAwards11,
  CoAwards10,
];

const responsive_one = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsive_four = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// variant = lifeAtAyana, lifeAtSite, Rewards
const SliderImages = ({ variant, numOfImages }) => {
  const images = {
    lifeAtAyana: lifeAtAyanaImages,
    lifeAtSite: lifeAtSiteImages,
    rewards: rewardsImages, // rewards and recognition
    recognition: recognitionImages,
  };

  const responsive = {
    1: responsive_one,
    4: responsive_four,
  };

  return (
    <section className="">
      <div className="">
        <Carousel
          autoPlay={true}
          infinite
          responsive={responsive[numOfImages]}
          itemClass="p-2"
        >
          {images[variant]?.map((item, index) =>
            variant == "recognition" ? (
              <div className="slider_image">
                <img className="img-fluid w-100 h-100" src={item} alt="arrow" />
              </div>
            ) : (
              <img
                key={`${variant}-${index}`}
                className="w-100 h-100"
                src={item}
                alt={`life-ayana-${index}`}
              />
            )
          )}
        </Carousel>
      </div>
    </section>
  );
};

export default SliderImages;
