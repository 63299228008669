import React from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import QHSE from "../../assets/pdf/QHSE.pdf";
import Logo from "../../assets/header-logo.svg";
const Navbar = () => {
  return (
    <>
      <div className="headerContainerNew">
        <header className="dark">
          <nav role="navigation">
            <div className="logoHeaderContainer">
              <NavHashLink exact activeclassname="is-active" to="/">
                <img className="navLogo" src={Logo} alt="Ayana" />
              </NavHashLink>
            </div>
            <a href="javascript:void(0);" className="ic menu" tabIndex="1">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </a>
            <a href="javascript:void(0);" className="ic close"></a>

            <ul className="main-nav">
              <li className="top-level-link">
                <NavHashLink to={`/`}>Home</NavHashLink>
              </li>

              <li className="top-level-link">
                <a className="mega-menu">
                  <span>About</span>
                </a>
                <div className="sub-menu-block">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="sub-menu-lists">
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#`}
                          >
                            About
                          </NavHashLink>
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#ceo`}
                          >
                            CEO'<span className="text-lowercase">s</span>{" "}
                            Message
                          </NavHashLink>
                        </li>
                        <li>
                          <NavHashLink to={`/about#purpose-vision`}>
                            Vision & Mission
                          </NavHashLink>
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#milestone`}
                          >
                            Milestones
                          </NavHashLink>
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#investors`}
                          >
                            Investors
                          </NavHashLink>
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#directors-board`}
                          >
                            Board
                          </NavHashLink>
                        </li>

                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#leadership`}
                          >
                            Leadership
                          </NavHashLink>
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/about#awards`}
                          >
                            Awards & Recognitions
                          </NavHashLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="top-level-link">
                <a className="mega-menu">
                  <span>Offerings and Projects</span>
                </a>
                <div className="sub-menu-block">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="sub-menu-lists">
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/offerings#`}
                          >
                            {" "}
                            Offerings{" "}
                          </NavHashLink>{" "}
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/offerings#projects`}
                          >
                            {" "}
                            Projects{" "}
                          </NavHashLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li className="top-level-link">
                <a className="mega-menu">
                  <span>Sustainability</span>
                </a>
                <div className="sub-menu-block">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="sub-menu-lists">
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/sustainability#`}
                          >
                            {" "}
                            Sustainability{" "}
                          </NavHashLink>{" "}
                        </li>
                        <li>
                          {" "}
                          <a href={QHSE} target="_blank" rel="noreferrer">
                            {" "}
                            QHSE{" "}
                          </a>{" "}
                        </li>
                        <li>
                          <NavHashLink
                            activeclassname="is-active"
                            to={`/governance#`}
                          >
                            {" "}
                            Corporate Governance{" "}
                          </NavHashLink>{" "}
                        </li>
                        <li>
                          <NavHashLink to={`/csr-initiative#`}>
                            {" "}
                            Community Development{" "}
                          </NavHashLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="top-level-link">
                <a className="mega-menu">
                  <span>Media</span>
                </a>
                <div className="sub-menu-block">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="sub-menu-lists">
                        <li>
                          <NavHashLink to={`/media#`}> Ayana News </NavHashLink>{" "}
                        </li>
                        <li>
                          <NavHashLink to={`/media#coverage`}>
                            {" "}
                            Coverage{" "}
                          </NavHashLink>{" "}
                        </li>
                        {/* <li>
                          <NavHashLink to={`/media#branding-assets`}>
                            {" "}
                            Branded Assets{" "}
                          </NavHashLink>{" "}
                        </li> */}
                        <li>
                          <NavHashLink to={`/blogs#`}> Blog </NavHashLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="top-level-link">
                <a className="mega-menu">
                  <span>Careers</span>
                </a>
                <div className="sub-menu-block">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="sub-menu-lists">
                        <li>
                          <NavHashLink to={`/careers#`}>
                            {" "}
                            Work with us{" "}
                          </NavHashLink>{" "}
                        </li>
                        <li>
                          <NavHashLink to={`/ayana-life#`}>
                            {" "}
                            Life@Ayana{" "}
                          </NavHashLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="top-level-link">
                <a className="mega-menu">
                  <span>Partnership</span>
                </a>
                <div className="sub-menu-block">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="sub-menu-lists">
                        <li>
                          <NavHashLink to={`/partners#`}>
                            {" "}
                            Partners{" "}
                          </NavHashLink>{" "}
                        </li>
                        <li>
                          <NavHashLink to={`/suppliers#`}>
                            {" "}
                            Suppliers &amp; Contractors{" "}
                          </NavHashLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li className="top-level-link">
                <NavHashLink to={`/contact#`}> Contact </NavHashLink>{" "}
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Navbar;
