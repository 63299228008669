import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import upload from "../../assets/upload.svg";
import "./vendor-form.scss";
import Navbar from "../../components/Navbar/Navbar";
import PreloaderForm from "../../components/PreloaderForm/PreloaderForm";
import vendor_banner from "../../assets/img/vendor_banner.png";



import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";


import config from "../../config";

const BASE_URL = config.base_url;

const vendorRegisterUrl = `${BASE_URL}api/vendor-registration`;



const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB

const checkMaxFileSize = (e) => {
  if(!e.target.files[0]) {
    return false;
  }
  // check for extensions 
  const extensions = ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx'];

  let fileExt = e.target.files[0].name.split('.').pop().toLowerCase();

  if(!extensions.includes(fileExt)){
    notifyWarning('Only PDF, JPG, JPEG, PNG, DOC, and DOCX files are allowed!');
    return false;
  }

  if(e.target.files[0].size > MAX_FILE_SIZE) {
    notifyWarning('File size should be less than 1 MB');
    return false;
  }
  return true;
}

const convertToFormData = (data)  => {
  const formData = new FormData();

  // loop through each of the keys in the dictionary and append it to the formdata
  for(const key in data) {
    if(data.hasOwnProperty(key)) {
      formData.append(key, data[key])
    }
   
  }
  return formData;
}

const notifyWarning = function(message) {
  toast.warning(message);
}

const notifyAlert = function(message) {
  toast.error(message);
}

const notifyInfo = function(message) {
  toast.info(message);
}

function capitalizeFirstLetter(val) {
  val = val.replaceAll('_', ' ');
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const req_fields = ['company_name', 'address', 'city', 'postal_code', 'state', 'country', 'phone_number', 'email', 'contact_person_name', 'management_team', 'directors_name', 'is_politicians_or_bureaucrats_or_public_officials', 'vendor_type', 'pan_number', 'pan_doc', 'gst_applicable', 'bank_name', 'branch', 'bank_account_number', 'ifsc_code', 'bank_address', 'account_type', 'micr_code', 'cancelled_cheque' , 'is_company_iso_accredited'  ];


const VendorForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    address: "",
    city: "",
    postal_code: "",
    state: "",
    country: "",
    phone_number: "",
    email: "",
    contact_person_name: "",
    website: "",
    management_team: "",
    directors_name: "",
    shareholding: "",
    is_politicians_or_bureaucrats_or_public_officials: "",
    politicians_or_bureaucrats_or_public_officials_details: "",
    vendor_type: "",
    vendor_type_certified_doc: null,
    pan_number: "",
    pan_doc: null,
    is_liable_tds: "",
    tds_doc: null,
    tds_category: "",
    rate_of_tds: "",
    gst_applicable: "",
    gst_number: "",
    gst_doc: null,
    non_gst_declaration_doc : null,
    ssl_regn_number: "",
    msme_doc: null,
    bank_name: "",
    branch: "",
    bank_account_number: "",
    ifsc_code: "",
    bank_address: "",
    account_type: "",
    micr_code: "",
    cancelled_cheque: null,
    turnover_2018_19_doc: null,
    turnover_2019_20_doc: null,
    turnover_2020_21_doc: null,
    it_return_2018_19_doc: null,
    it_return_2019_20_doc: null,
    it_return_2020_21_doc: null,
    networth: "",
    managerial_staff: "",
    supervisory_staff: "",
    skilled_labour: "",
    unskilled_labour: "",
    customer_1: "",
    customer_1_description: "",
    customer_1_bill_2010_11: "",
    customer_2: "",
    customer_2_description: "",
    customer_2_bill_2010_11: "",
    is_company_iso_accredited: "",
    iso_doc: null,
    is_company_have_other_accredited: "",
    annexure_doc: null,
    is_incorporations_doc: "",
    incorporations_doc: null,
    is_roc_doc: "",
    roc_doc: null,
    is_shareholding_pattern_doc: "",
    shareholding_pattern_doc: null,
    is_latest_telephone_bill_doc: "",
    latest_telephone_bill_doc: null,
    is_any_utility_bill_doc: "",
    any_utility_bill_doc: null,
    is_latest_bank_statement_doc: "",
    latest_bank_statement_doc: null,
    is_income_sales_service_tax_doc: "",
    income_sales_service_tax_doc: null,
    is_audited_balance_sheet_doc: "",
    audited_balance_sheet_doc: null,
    is_networth_certificate_by_CA_doc: "",
    networth_certificate_by_CA_doc: null,
    is_latest_order_book_doc: "no",
    latest_order_book_doc: null
  });
  
  const reqFieldRef = useRef([]);


  const handleInput  = (e) => {
    setFormData(prev => ({...prev, [e.target.name]: e.target.value}));
  }

  const handleFileChange = (e) => {

    const isValidFile  = checkMaxFileSize(e);
    if(isValidFile) {
      setFormData(prev => {
        return (
          {...prev, [e.target.name]: e.target.files[0]}
        )
      })
    }else {
      setFormData(prev => {
        return (
          {...prev, [e.target.name]: null}
        )
      })
    }
  }


  const checkReqFields = () => {
    // if all the required fields are there it will it will send true or false
    for(const key in formData) {
      // check if its a required key
      if(req_fields.includes(key)) {
          if(!formData[key]) {
            let missingField = capitalizeFirstLetter(key);
            let message;

            reqFieldRef.current[key].focus();

            switch(key){

              case "ifsc_code": message = "IFSC Code is required.";
              break;

              case "micr_code": message = "MICR Code is required.";
              break;

              case "cancelled_cheque": message = "Please upload Cancelled Cheque / Bank Letter";
              reqFieldRef.current['account_type'].focus();
              break;

              case "pan_doc": message = "Please Upload your Pan Card";
              reqFieldRef.current["pan_number"].focus();
              break;

              case "is_company_iso_accredited":  message= "Please select whether company is ISO Accredited or not.";
              break;

              case "gst_applicable": message = "Please select whether GST is applicable or not. ";
              break;

              case "is_politicians_or_bureaucrats_or_public_officials":
                message = "Please select whether the company's share holders/Directors are Politicians/Bureaucrats/Public Officials"
                break;

              default: 
              message = `${missingField} is required`;
              
              break;
            }


            notifyAlert(message)
            return false;
          }
          // check for inner conditions 
          if (key === "gst_applicable") {
            const isGstApplicable = formData[key] === "yes";
            let missingField = isGstApplicable
              ? !formData['gst_number'] ? 'GST Number' : !formData['gst_doc'] ? 'GST Document' : null
              : !formData['non_gst_declaration_doc'] ? 'Non GST Declaration Document' : null;
          
            if (missingField) {
              notifyAlert(`${missingField} is required!`);
              if (isGstApplicable && ( missingField === 'GST Number' || missingField === "GST Document" ) ) {
                reqFieldRef.current['gst_number'].focus();
              }
              return false;
            }
          }
          if(key == "is_company_iso_accredited") {
            if(formData[key] == "yes") {
              if(!formData['iso_doc']) {
                reqFieldRef.current['is_company_iso_accredited'].focus();
                notifyAlert('ISO Document is required!');
                return false;
              }
            }
          }
      }
    }
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    
    // form validation 

    const isFormValidated = checkReqFields();
    if(!isFormValidated){
      setIsLoading(false);
      return;
    }

    setIsLoading(true);


    // convert data to formdata 
    const data = convertToFormData(formData);

    // give a http request 
    const response = await fetch(
      vendorRegisterUrl,
      {
        method: "POST",
        headers: {
        },
        body: data,
      }
    );

    if(response.status == 413) {
      alert('content too large to upload. Kindly contact admin.');
      setIsLoading(false);
      return;
    }

    if(response.status == 500) {
      alert('server error. Kindly contact admin');
      setIsLoading(false);
      return;
    }

    const response_data = await response.json();

    if (response_data.status === 201) {
      navigate("/thankyou?s=vendor");
    }
    else if(response_data.status ===400){
      setIsLoading(false);
       alert("please enter mandatary field");
    }
    else{
      alert("something went wrong")
    }
  }

  return (
    <div className="vendor-form">
      <Navbar />
      <ToastContainer />
      <section
        className="position-relative banner_image_overlay pb-0"
        id={"vendor-form"}
      >
        <img src={vendor_banner} alt="" className="img-fluid w-100" />
      </section>
      <section className="vpgform">
        <div className="container">
          <div className="form-heading mt-3">
            <div class="title pt-4">
              <h4 className="m-auto">Supplier Registration Form</h4>
            </div>
            {/* <h1>Vendor Registration Form</h1> */}
            <p className="mt-2">
              Fill form or mail us at{" "}
              <a href="mailto:supplier@ayanapower.com" className="text-dark fw-bold">
                supplier@ayanapower.com
              </a>
              &nbsp;enclosing your credentials and areas in which you would like
              to work with us.
            </p>
          </div>
          <div id="vr_form">
            <form
              className="row g-3 justify-content-between mt-3"
              onSubmit={handleSubmit}
            >
              {/* Company Information */}
              <div className="col-md-12">
                <h2>Company Information</h2>
              </div>
              <div className="col-md-6">
                <input 
                  type="text"
                  className="form-control"
                  placeholder="Name of Company *"
                  name="company_name"
                  value={formData.company_name}
                  onChange={e => handleInput(e)}
                  ref={el => reqFieldRef.current['company_name'] = el}

                />
              </div>
              <div className="col-md-6">
                <input  
                  type="text"
                  className="form-control"
                  placeholder="Address *"
                  name="address"
                  value={formData.address}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['address'] = el}

                  
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City *"
                  name="city"
                  value={formData.city}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['city'] = el}

                  
                />
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control"
                  inputMode="numeric"
                  placeholder="Postal Code *"
                  name="postal_code"
                  value={formData.postal_code}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['postal_code'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State *"
                  name="state"
                  value={formData.state}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['state'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control " 
                  placeholder="Country *"
                  name="country"
                  value={formData.country}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['country'] = el}

                />
              </div>
              <div className="col-md-6">

                <div class="position-relative">
        
                <input
                  type="number"
                  className="form-control vendor_phone_number_input"
                  placeholder="Phone *"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['phone_number'] = el}
                />
        
        <label  className="vendor_phone_number_label">+91
        </label>
    </div>
              </div>
      
              <div className="col-md-6">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email *"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['email'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Person Name *"
                  name="contact_person_name"
                  value={formData.contact_person_name}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['contact_person_name'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="url"
                  className="form-control"
                  placeholder="Website (e.g. https://www.example.com)"
                  name="website"
                  value={formData.website}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              {/* End Company Information */}

              {/* Ownership Details */}
              <div className="col-md-12 space-40">
                <h2>Ownership Details</h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Management Team (MD / CEO / Partners / Proprietor) *"
                  name="management_team"
                  value={formData.management_team}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['management_team'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Names of Directors in the Company *"
                  name="directors_name"
                  value={formData.directors_name}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['directors_name'] = el}

                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Shareholding (provide information on corporate structure/ownership details)"
                  name="shareholding"
                  value={formData.shareholding}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="col-md-12">
                <p>
                  Does the company have any Shareholders or Directors who are
                  Politicians/Bureaucrats / Public officials? <span className="text-danger"> * </span>
                </p>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                  
                    id="inlineRadio1"
                    value="yes"
                    name="is_politicians_or_bureaucrats_or_public_officials"
                    onChange={(e) => handleInput(e)}
                    ref={el => reqFieldRef.current['is_politicians_or_bureaucrats_or_public_officials'] = el}

                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="inlineRadio2"
                    value="no"
                    name="is_politicians_or_bureaucrats_or_public_officials"
                    onChange={(e) => handleInput(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-12">
              {
                formData.is_politicians_or_bureaucrats_or_public_officials == "yes" && (
                  <input
                  type="text"
                  className="form-control"
                  placeholder="( If yes, kindly provide details ) "
                  name="politicians_or_bureaucrats_or_public_officials_details"
                  value={formData.politicians_or_bureaucrats_or_public_officials_details}
                  onChange={(e) => handleInput(e)} 
                  autoFocus
                />
                )
              }

              </div>

              {/* End Ownership Details */}

              {/* Vendor Type */}

              <div className="col-md-12 space-40">
                <h2> Vendor Type (Please Select) <span className=""> * </span></h2>
              </div>
              <div className="col-md-3">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="vendor_type_service"
                    name="vendor_type"
                    value="Service"
                    onChange={(e) => handleInput(e)}   
                    ref={el => reqFieldRef.current['vendor_type'] = el}
 
                  />
                  <label className="form-check-label" htmlFor="vendor_type_service">
                    Service
                  </label>
                </div>
                <p>MOA & AOA (Applicable/ Not Applicable)</p>
              </div>
              <div className="col-md-6">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="vendor_type_materials"
                    name="vendor_type"
                    value="materials"
                    onChange={(e) => handleInput(e)}
                  />
                  <label className="form-check-label" htmlFor="vendor_type_materials">
                    Materials
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                   type="radio"
                    className="form-check-input"
                    id="vendor_type_composite"
                    name="vendor_type"
                    value="composite"
                    onChange={(e) => handleInput(e)}
                  />
                  <label className="form-check-label" htmlFor="vendor_type_composite">
                    Composite
                  </label>
                </div>

                <p>
                  If Applicable: Attach a certified copy along with a
                  certificate of incorporation
                </p>
              </div>
              <div className="col-md-3">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="vendor_type_certified_doc"
                      onChange={(e) => {
                        handleFileChange(e);
                      }
                       
                      }
                      accept="image/*,.pdf"
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  {/* <p> Upload </p> */}
                  {formData.vendor_type_certified_doc == null ? (
                    <p> Upload </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              {/* End Vendor Type */}

              {/* Statutory Requirement */}

              <div className="col-md-12 space-40">
                <h2>Statutory Requirement</h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="PAN Number *"
                  name="pan_number"
                  value={formData.pan_number}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['pan_number'] = el}

                />
              </div>

              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="pan_doc"
                      onChange={(e) => {
                       
                        handleFileChange(e);
                      }}
                      ref={el => reqFieldRef.current['pan_doc'] = el}
                 
                      accept="image/*,.pdf"
         
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> Upload your Pan Card </p>
                  {formData.pan_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">


                <label className="form-label"> Whether Liable to TDS </label>
                <div className="d-flex gap-5">
                <div className="form-check">
                  <input  type="radio" className="form-check-input" name="is_liable_tds" value="yes" 
                  onChange={e => handleInput(e)} />
                  <label className="form-check-label"> Yes </label>
                </div>
                <div className="form-check">
                  <input type="radio" className="form-check-input" name="is_liable_tds" value="no"
                  onChange={e => handleInput(e)} />
                  <label className="form-check-label"> No </label>
                </div>
                </div>
              </div>
              <div className="col-md-6">
           
              
    
              </div>
              {
                
              }
              <div className="col-md-6">
            
            {
              formData.is_liable_tds == "yes" && (
                <>
                <input
                  type="text"
                  className="form-control"
                  placeholder="TDS Category "
                  name="tds_category"
                  value={formData.tds_category}
                  onChange={(e) => handleInput(e)}
                  autoFocus
                />

                </>
              )
            }
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
              {
                formData.is_liable_tds == "yes" && (
                  <>
                  <input
                  type="text"
                  className="form-control"
                  placeholder="Rate of TDS "
                  name="rate_of_tds"
                  value={formData.rate_of_tds}
                  onChange={(e) => handleInput(e)}
                />
                 <div className="upload mt-3 mb-2">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="tds_doc"
                      onChange={(e) => {
                        handleFileChange(e);
                      } 
                      }
                 
                      ref={el => reqFieldRef.current['tds_doc'] = el}
                      accept="image/*,.pdf"
                     
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> Upload if YES for TDS </p>
                  {formData.tds_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
                  </>
                )
              }
               
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">

                <label className="form-label"> Is Gst Applicable ? <span className="text-danger"> * </span></label>

                <div className="d-flex gap-5  mb-3">

                <div className="form-check">
                  <input type="radio" name="gst_applicable" className="form-check-input"  value="yes" onChange={e => {
                    setFormData(prev => ({...prev, 'non_gst_declaration_doc': null, [e.target.name]: e.target.value}))
                  }}
                  ref={el => reqFieldRef.current['gst_applicable'] = el}



                  />
                  <label className="form-check-label"  > Yes  </label>
                </div>

                <div className="form-check">
                  <input type="radio" name="gst_applicable" className="form-check-input" 
                    value="no"
                    onChange={e =>  {
                      // clear the gst doc 
                      setFormData(prev => ({...prev, 'gst_doc' : null, [e.target.name] : e.target.value}))
                    }}
                  />
                  <label className="form-check-label" > No  </label>
                </div>

                </div>

                {
                  formData.gst_applicable == "yes" && (
                    <>

             
                    <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="GST No *"
                  name="gst_number"
                  value={formData.gst_number}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['gst_number'] = el}
                />

                <div className="upload mb-4">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="gst_doc"
                      onChange={(e) => {
                      
                        handleFileChange(e)
                      } 
                      }
                      accept="image/*,.pdf"
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" 
                      />
                    </span>
                  </label>
                  <p>
                    Upload GST Registration Certificate if Supply of Material <span className="text-danger">*</span>
                  </p>

                  {formData.gst_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>

                </>
                  )  
                }

                {
                  formData.gst_applicable == "no" && (
                      <>
                      <div className="upload mb-4">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="non_gst_declaration_doc"
                      onChange={(e) => {
                        
                        handleFileChange(e)
                      } 
                      }
                      accept="image/*,.pdf"
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p>
                    Upload Non GST Declaration <span className="text-danger">*</span>
                  </p>

                  {formData.non_gst_declaration_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
                      </>

                  ) 
                }
                
              </div>
              <div className="col-md-6">

              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="MSME / SSI Regn No."
                  name="ssl_regn_number"
                  value={formData.ssl_regn_number}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      name="msme_doc"
                      className="d-none"
                      onChange={(e) => {
                        
                        handleFileChange(e)
                      } 
                      }
                      accept="image/*,.pdf"
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  
                  <p >Upload MSME Certificate</p>

                  {formData.msme_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>

              {/* End Statutory Requirement */}

              {/* Bank Details */}

              <div className="col-md-12 space-40">
                <h2>Bank Details <span className="text-danger"> * </span></h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bank Name *"
                  name="bank_name"
                  value={formData.bank_name}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['bank_name'] = el}
                />
              </div>
              <div className="col-md-6">
                <input
     
                  type="text"
                  className="form-control"
                  placeholder="Branch *"
                  name="branch"
                  value={formData.branch}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['branch'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Bank Account No *"
                  name="bank_account_number"
                  value={formData.bank_account_number}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['bank_account_number'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="IFSC CODE *"
                  name="ifsc_code"
                  value={formData.ifsc_code}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['ifsc_code'] = el}

                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address *"
                  name="bank_address"
                  value={formData.bank_address}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['bank_address'] = el}

                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bank Account Type *"
                  name="account_type"
                  value={formData.account_type}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['account_type'] = el}
                />
              </div>
              <div className="col-md-6">
                <input
        
                  type="text"
                  className="form-control"
                  placeholder="MICR CODE *"
                  name="micr_code"
                  value={formData.micr_code}
                  onChange={(e) => handleInput(e)}
                  ref={el => reqFieldRef.current['micr_code'] = el}
                />
              </div>
              <div className="col-lg-6">
                <div className="row px-3">
                  <div className="col-md-5 yearview">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="cancelled_cheque"
                          onChange={(e) => {
                          
                            handleFileChange(e);
                          } 
                          }
                          ref={el => reqFieldRef.current['cancelled_cheque'] = el}
                          accept="image/*,.pdf"
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.cancelled_cheque == null ? (
                        <p> Upload Cancelled Cheque / Bank Letter </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* End Bank Details */}

              {/* Financial Information */}

              <div className="col-md-12 space-40">
                <h2>
                  Financial Information(Kindly Provide the last 3 Years IT
                  Returns & Balance Sheet)
                </h2>
              </div>
              <div className="col-md-2">
                <span className="cc_cc">Turnover:</span>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2018-19</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="turnover_2018_19_doc"
                          onChange={(e) =>
                          {
                          
                            handleFileChange(e);
                          }  
                          }
                          accept="image/*,.pdf"
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.turnover_2018_19_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2019-20</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="turnover_2019_20_doc"
                          onChange={(e) =>
                          {
                            
                            handleFileChange(e)
                          }
                           
                          }
                          accept="image/*,.pdf"
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.turnover_2019_20_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2020-21</div>
                  <div className="col-6">
                    {" "}
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="turnover_2020_21_doc"
                          onChange={(e) =>
                          {
                          
                            handleFileChange(e)
                          }
                           
                          }
                          accept="image/*,.pdf"
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.turnover_2020_21_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <span className="cc_cc">IT Return:</span>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2018-19</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="it_return_2018_19_doc"
                          onChange={(e) => {
                            
                            handleFileChange(e)
                          }
                           
                          }
                          accept="image/*,.pdf"
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.it_return_2018_19_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2019-20</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="it_return_2019_20_doc"
                          onChange={(e) => {
                            
                            handleFileChange(e)
                          }
                           
                          }
                          accept="image/*,.pdf"
                        />

                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.it_return_2019_20_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2020-21</div>
                  <div className="col-6">
                    {" "}
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          name="it_return_2020_21_doc"
                          onChange={(e) => {
                          
                            handleFileChange(e)
                          }
                            
                          }
                          accept="image/*,.pdf"
                        />

                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {formData.it_return_2020_21_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                 
                  className="form-control"
                  placeholder="Networth Value Attested by Chartered Accountant"
                  name="networth"
                  value={formData.networth}
                  onChange={(e) => handleInput(e)}
                />
              </div>

              {/* End Financial Information */}

              {/* Organizational Strength */}

              <div className="col-md-12 space-40">
                <h2>Organizational Strength</h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Managerial Staff"
                  name="managerial_staff"
                  value={formData.managerial_staff}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Supervisory Staff"
                  name="supervisory_staff"
                  value={formData.supervisory_staff}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Skilled Labour"
                  name="skilled_labour"
                  value={formData.skilled_labour}
                  onChange={(e) => handleInput(e)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Unskilled Labour"
                  name="unskilled_labour"

                  value={formData.unskilled_labour}
                  onChange={(e) => handleInput(e)}
                />
              </div>

              {/* End Organizational Strength */}

              {/* List of Major Customers */}
              <div className="col-md-12 space-40">
                <h2>List of Major Customers (Top 2)</h2>
              </div>
              <div className="col-md-12 table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th>Sl.No:</th>
                      <th>Customers</th>
                      <th>Description of Work Done & Capacity</th>
                      <th>
                        UTotal billing in the last financial year(2010 -11)(Rs.)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          name="customer_1"
                          value={formData.customer_1}
                          onChange={(e) => handleInput(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          name="customer_1_description"
                          value={formData.customer_1_description}
                          onChange={(e) =>
                            handleInput(e)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          name="customer_1_bill_2010_11"
                          value={formData.customer_1_bill_2010_11}
                          onChange={(e) =>
                            handleInput(e)
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>2</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Customers"
                          name="customer_2"
                          value={formData.customer_2}
                          onChange={(e) => handleInput(e)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          name="customer_2_description"
                          value={formData.customer_2_description}
                          onChange={(e) =>
                            handleInput(e)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          name="customer_2_bill_2010_11"
                          value={formData.customer_2_bill_2010_11}
                          onChange={(e) =>
                            handleInput(e)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* End List of Major Customers */}

              {/* Quality System Particulars */}

              <div className="col-md-12 space-40">
                <h2>Quality System Particulars</h2>
              </div>
              <div className="col-md-12">
                <label> Is the Company ISO 9000/ ISO 14000 Accredited? </label>
              </div>
              <div className="row">
              <div className="col-md-6">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_company_iso_accredited"
                    id="is_company_iso_accredited_yes"
                    value="yes"
                    onChange={(e) =>
                      setFormData(prev => ({...prev, "is_company_have_other_accredited": null, [e.target.name]: e.target.value}))
                    }
                    ref={el => reqFieldRef.current['is_company_iso_accredited'] = el}
                  />
                  <label className="form-check-label" htmlFor="is_company_iso_accredited_yes">
                    Yes
                  </label>
                </div>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_company_iso_accredited"
                    id="is_company_iso_accredited_no"
                    value="no"
                    onChange={(e) =>
                      setFormData(prev => ({...prev, "iso_doc": null, [e.target.name]:e.target.value}))
                    }
                  />
                  <label className="form-check-label" htmlFor="is_company_iso_accredited_no">
                    No 
                  </label>
                </div>

                {
                formData.is_company_iso_accredited == "no" && (
                  <input
                  type="text"
                  className="form-control my-3 "
                  placeholder="( Please specify if any other accreditation was obtained. )"
                  name="is_company_have_other_accredited"
                  value={formData.is_company_have_other_accredited}
                  onChange={(e) =>
                   handleInput(e)
                  }
                  autoFocus
                />
                )
              }

                  

                <span className="note">
                  <b>*Note :</b> Please attach separate Annexure sheets for of
                  List of equipment available and a List of Testing Equipment
                  available*
                </span>
              </div>
              <div className="col-md-6">
                {
                    formData.is_company_iso_accredited == "yes" && (
                      <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="iso_doc"
                      onChange={(e) => {
                           
                            handleFileChange(e)
                      } 
                      
                      }
                      accept="image/*,.pdf"
                      
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> (if yes, enclose a copy of the certificates)*</p>
                  {formData.iso_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
                    )
                }

              </div>
              </div>
             
             
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      name="annexure_doc"
                      onChange={(e) => {
                            handleFileChange(e)
                      }
                   
                      
                      }
                      accept="image/*,.pdf"
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> Upload Annexure sheets </p>
                  {formData.annexure_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>

              {/* End Quality System Particulars */}

              {/* Documents to be enclosed */}

              <div className="col-md-12 space-40">
                <h2> Additional Documents to be enclosed: </h2>
              </div>
              <div className="col-md-12 table-responsive">
                <table className="table  mobile-view">
                  <tbody>
                 
                    <tr>
                      <td>
                        MOA & AOA (certified copy along with the certificate of
                        incorporations) :
                      </td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
    
                          <input
                            type="radio"
                            value="yes"
                            className="form-check-input"
                           
                            name="is_incorporations_doc"
                            onChange={(e) => handleInput(e)}
                         
                          />
                          <label className="form-check-label" >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">

                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_incorporations_doc"
                            onChange={(e) => {

                              setFormData(prev => ({...prev, "incorporations_doc": null, [e.target.name]: e.target.value }))
                             
                            }
                              
                              }
                          
                          />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                       {
                        formData.is_incorporations_doc == "yes" && (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="incorporations_doc"
                              onChange={(e) => {
                                handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"
                            
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.incorporations_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                       }
                      </td>
                    </tr>
                    <tr>
                      <td>ROC :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
 
                          <input
                            type="radio"
                            className="form-check-input"
                            name="is_roc_doc"
                            value="yes"
                            onChange={e => {
                              handleInput(e)
                            }  
                            }
                           
                          />
                          <label className="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
          
                          <input
                            type="radio"
                            className="form-check-input"
                            name="is_roc_doc"
                            value="no"
                            onChange={e => {
                              setFormData(prev => ({...prev, "roc_doc": null, [e.target.name]: e.target.value}))
                            }}
                          />
                          <label className="form-check-label">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                      {
                        formData.is_roc_doc == "yes" && (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="roc_doc"
                              onChange={(e) =>  {
                                handleFileChange(e)
                              }  
                              }
                              accept="image/*,.pdf"
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.roc_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                      }
                       
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Supporting Document of Company Shareholding Pattern :
                      </td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">

                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_shareholding_pattern_doc"
                            value="yes"
                            onChange={e => handleInput(e)}

                          />
                          <label className="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
      
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_shareholding_pattern_doc"
                            value="no"
                            onChange={e => {
                              setFormData(prev => ({...prev, "shareholding_pattern_doc": null, [e.target.name]: e.target.value}))
                            }}

                          />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                       {
                        formData.is_shareholding_pattern_doc == "yes" && (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="shareholding_pattern_doc"
                              onChange={(e) => {
                                handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"
                            />

                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.shareholding_pattern_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                       }
                      </td>
                    </tr>
                    <tr>
                      <td>Latest telephone (landline) bill (or) :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_latest_telephone_bill_doc"
                            value="yes"
                            onChange={e => handleInput(e)}

                          />
                          <label className="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_latest_telephone_bill_doc"
                            value="no"
                            onChange={e => {
                             setFormData(prev => ({...prev, "latest_telephone_bill_doc": null, [e.target.name]: e.target.value}))
                            }}
                          />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {
                          formData.is_latest_telephone_bill_doc == 'yes' && (
                            <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="latest_telephone_bill_doc"
                              onChange={(e) => {
                                handleFileChange(e)
                              }
                              
                              }
                              accept="image/*,.pdf"
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.latest_telephone_bill_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                          )
                        }
                       
                      </td>
                    </tr>
                    <tr>
                      <td>Any utility bill in the name of the entity (or) :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_any_utility_bill_doc"
                            value="yes"
                            onChange={e => handleInput(e)}
                          />
                          <label className="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_any_utility_bill_doc"
                            value="no"
                            onChange={e => {
                             setFormData(prev => ({...prev, "any_utility_bill_doc": null, [e.target.name]: e.target.value}))
                            }}
                          />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                      {
                        formData.is_any_utility_bill_doc == 'yes' && (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="any_utility_bill_doc"
                              onChange={(e) => {
                               handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.any_utility_bill_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                      }
                      
                      </td>
                    </tr>
                    <tr>
                      <td>Latest bank statement. (Proof of address) :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_latest_bank_statement_doc"
                            value="yes"
                            onChange={e => handleInput(e)}
                            />
                          <label className="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                              name="is_latest_bank_statement_doc"
                              value="no"
                              onChange={e => {
                                setFormData(prev => ({...prev, "latest_bank_statement_doc": null, [e.target.name]: e.target.value}))
                              }}
                          />
                          <label className="form-check-label">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {
                          formData.is_latest_bank_statement_doc == 'yes' && (
                            <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="latest_bank_statement_doc"
                              onChange={(e) => {
                               handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.latest_bank_statement_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                          )
                        }
                       
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Income tax, sales tax, and service tax assessment orders
                        :
                      </td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_income_sales_service_tax_doc"
                            value="yes"
                            onChange={e => handleInput(e)}
                          />
                          <label className="form-check-label" >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_income_sales_service_tax_doc"
                            value="no"
                        onChange={e => {
                          setFormData(prev => ({...prev, "income_sales_service_tax_doc": null, [e.target.name]: e.target.value}))
                        }}
                          />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                       {
                        formData.is_income_sales_service_tax_doc == "yes" &&  (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="income_sales_service_tax_doc"
                              onChange={(e) => {
                                handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.income_sales_service_tax_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                       }
                      </td>
                    </tr>
                    <tr>
                      <td> Audited balance sheet :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_audited_balance_sheet_doc"
                            value="yes"
                          onChange={e => handleInput(e)}
                          />
                          <label className="form-check-label" >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_audited_balance_sheet_doc"
                            value="no"
                        onChange={e => {
                          setFormData(prev => ({...prev, "audited_balance_sheet_doc": null, [e.target.name]: e.target.value}))
                        }}

                          />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                      {
                        formData.is_audited_balance_sheet_doc == "yes" && (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="audited_balance_sheet_doc"
                              onChange={(e) => {
                                handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"

                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.audited_balance_sheet_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                      }
                       
                      </td>
                    </tr>
                    <tr>
                      <td>Networth certificate by CA :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_networth_certificate_by_CA_doc"
                            value="yes"
                            onChange={e => handleInput(e)}
                            />
                          <label className="form-check-label" >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_networth_certificate_by_CA_doc"
                            value="no"
                            onChange={e => {
                              setFormData(prev => ({...prev, "networth_certificate_by_CA_doc": null, [e.target.name]: e.target.value}))
                            }}
                          />
                          <label className="form-check-label">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {
                          formData.is_networth_certificate_by_CA_doc == "yes" && (
                            <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="networth_certificate_by_CA_doc"
                              onChange={(e) => {
                                  handleFileChange(e)
                              }
                              
                              }
                              accept="image/*,.pdf"
              
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {formData.networth_certificate_by_CA_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                          )
                        }
                      
                      </td>
                    </tr>
                    <tr>
                      <td>Latest order book :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_latest_order_book_doc"
                            value="yes"
                          onChange={e => handleInput(e)}
                          />
                          <label className="form-check-label">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="is_latest_order_book_doc"
                            value="no"
                           onChange={e => {
                            setFormData(prev => ({...prev, "latest_order_book_doc": null, [e.target.name]: e.target.value}))
                           }}
                            />
                          <label className="form-check-label" >
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                      {
                        formData.is_latest_order_book_doc == "yes"&& (
                          <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              name="latest_order_book_doc"
                              onChange={(e) => {
                                handleFileChange(e)
                              }
                               
                              }
                              accept="image/*,.pdf"
                      
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="form-img"
                              />
                            </span>
                          </label>

                          {formData.latest_order_book_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                        )
                      }
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* <p>( Doc No: ARPPL-SCM-004 )</p> */}
              </div>

              {/* End Documents to be enclosed */}
              <div className="preloadVenContainer">
                <div className=" preloaderbtnVendor">
                  {isLoading && (
                    <div className="preloaderbtnSpinVendor">
                      <PreloaderForm />
                    </div>
                  )}
                  <input
                    type="submit"
                    value="submit"
                    className="vendorSubmitButton mb-3"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default VendorForm;
