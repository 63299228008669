import Husain_Surti from "../../assets/img/employee_stories/md_husain_surti.png";
import Nagavenni_hakkapakki from "../../assets/img/employee_stories/nagavenni_hakkapakki.png";
import Piyush_Srivastav from "../../assets/img/employee_stories/piyush.png";
import syed_nawaz from "../../assets/img/employee_stories/syed_nawaz.png";
import sowmya from "../../assets/img/employee_stories/sowmya.png";
import srinivas from "../../assets/img/employee_stories/srinivas.png";
import shinam from "../../assets/img/employee_stories/shinam.png";
import rajesh from "../../assets/img/employee_stories/rajesh.png";

const people = [
  {
    id: 1,
    image: Husain_Surti,
    name: "~ Mohammed Husain Surti",
    description: "Senior Manager - Projects",
    quote:
      "Since I have joined Ayana in October 2023 it has been a joyful 3 months with all the coworkers having cordial relations with each other working together towards common goal of powering the nation. There has been never a dull moment right from first day of on boarding. All are employees and management are very lively and easily approachable. Delighted to be a part for these young and growing organization.",
  },
  {
    id: 2,
    image: Nagavenni_hakkapakki,
    name: "~ Nagaveni Hakkapakki",
    description: "Management Trainee - Engineering",
    quote:
      "Hi all, I am writing this to extend my heartfelt appreciation for the incredible opportunity to embark on my professional journey with Ayana. As a recent college graduate, joining the corporate sector has been an exciting and enlightening experience, and I’m very much grateful the mentorship provided my senior team members. Thank you once again for entrusting me with this opportunity. I look forward to a rewarding journey with Ayana.",
  },
  {
    id: 3,
    image: syed_nawaz,
    name: "~ Syed Khaja Nawaz",
    description: "Asst. General Manager - Legal",
    quote:
      "I want to express my heartfelt appreciation to Team Ayana, as the Company has been consistently fixed to commitment on legal, ethical, and strict processes in order to strengthen the company's interests and upheld its reputation. I and our legal team are fixed to this commitment as to succeed in all milestones where Ayana steps-in.",
  },
  {
    id: 4,
    image: Piyush_Srivastav,
    name: "~ Piyush Srivastav",
    description: "Assistant General Manager - Data Analytics",
    quote:
      "I’m a proud member of team Ayana and excited to contribute to the organisational “Digitalisation of Renewable Assets” journey. Ayana is building a world-class excellence centre for advanced analytics and machine learning to improve the performance of its renewable assets. The open office culture promotes agile processes and easy access to leadership, making it a great workplace in the renewable energy industry in India.",
  },
  {
    id: 5,
    image: sowmya,
    name: "~ Sowmya R",
    description: "Company Secretary",
    quote:
      "I started my Company Secretary journey at Ayana in 2018, in the corporate secretarial and compliance team, which has been a remarkable experience in terms of my professional and personal growth. Working in a Company that values attention to detail and a commitment to perfection has taught me the importance of precision in my work. Ayana has provided me with ample opportunities to deep dive into complex issues and resolve it through the guidance and mentorship of experienced professionals. Being part of a team that upholds the highest standards of ethics and integrity, has instilled in me a sense of pride and responsibility. Ayana has not only enriched my professional journey but has also nurtured my personal growth, enabling me to become a more well-rounded individual. On the personal front, Ayana has given me an identity as an individual in my personal life and I feel, Ayana is an extended family where I feel very safe.",
  },
  {
    id: 6,
    image: srinivas,
    name: "~ N.Srinivas Reddy",
    description: "Asst. General Manager - SCM (Insurance)",
    quote:
      "Being a part of the Ayana family fills me with immense pride. The company's work environment, culture, and the behaviour of my fellow employees are truly amazing, providing the ideal platform to nurture my professional career. The working culture is exceptional, and the support from higher management is truly commendable. The perfect work-life balance adds to the overall experience, allowing me to thoroughly enjoy my time here. I am fortunate to be part of one of the best SCM teams, who provide unwavering support. The days simply fly by due to the enriching cross-cultural interactions among employees. Ayana is where I thrive and continue to grow both personally and professionally.",
  },
  {
    id: 7,
    image: shinam,
    name: "~ Shinam Agarwal",
    description: "Deputy Manager - Projects",
    quote:
      "At Ayana, there is a perfect blend of work and fun, creating a vibrant workplace where employees wholeheartedly support one another. Despite being here for just a few days, I already feel a strong sense of enjoyment and anticipation when coming to work, fueled by the remarkable motivation I receive from my incredible team. Being a part of Ayana fills me with joy, and I am grateful for the opportunity to contribute to the company's success.",
  },
  {
    id: 7,
    image: rajesh,
    name: "~ Rajesh Rudrappa",
    description: "Finance Controller - Finance and Accounts",
    quote:
      "Being with Ayana from the very beginning has been an incredible journey. I have witnessed first-hand the remarkable growth of this company, from its humble beginnings to its current stature of employing hundreds of talented individuals. It fills me with immense pride to have been part of this transformative process. As Ayana continues to make significant strides in the renewable energy industry, I am excited to be a part of its future endeavours. This journey has not only shaped my professional growth but has also allowed me to contribute meaningfully towards a sustainable future.",
  },
];

export default people;
