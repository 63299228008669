import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { HashLink } from "react-router-hash-link";
import CookieConsent from "../../components/Cookie/CookieConsent";

import "./footer.scss";
import AyanaImageLogoFooter from "../../assets/footer-logo.svg";
import site_linkedin from "../../assets/img/site_linkedin.png";
import footer_img from "../../assets/img/footer_img.png";

import QHSE from "../../assets/pdf/QHSE.pdf";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer shadow-lg mt-2">
      <div className="container-fluid">
        <div className="row g-4">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <Link to="/">
              <img
                className="ayanaLogo w-100"
                src={AyanaImageLogoFooter}
                alt="footer-logo"
              />
            </Link>
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-3 ps-md-5 px-lg-0">
            <p className="linksHeader">QUICK LINKS</p>
            <hr />
            <div className="row footerParagraphs">
              <div className="col-12 col-sm-12 col-md-6 col-xl-6">
                <ul>
                  <li>
                    <HashLink
                      to="/about#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      About Us
                    </HashLink>
                  </li>
                  <li>
                    <HashLink to="/media#">Media</HashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/offerings#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Offerings & Projects
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/sustainability#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Sustainability
                    </NavHashLink>
                  </li>
                  <li>
                    <a href={QHSE} target="_blank" rel="noreferrer">
                      QHSE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-xl-6">
                <ul>
                  <li>
                    <NavHashLink
                      to="/ayana-life#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Life @ Ayana
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/suppliers#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Suppliers
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/partners#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Partners
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/governance#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Corporate Governance
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/contact#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Contact Us
                    </NavHashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <p className="linksHeader">Other Links</p>
            <hr />
            <div className="row footerParagraphs mt-3">
              <div className="col-md-6 py-0">
                <ul className="mb-0">
                  <li>
                    <NavHashLink
                      to="/sitemap#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Sitemap
                    </NavHashLink>
                  </li>
                  <li>
                    <a
                      href="https://portal.ayanapowerrenewables.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Intranet link
                    </a>
                  </li>
                  {/* <li>Tags</li> */}
                </ul>
              </div>
              <div className="col-md-6 py-0">
                <ul className="mb-0">
                  <li>
                    <NavHashLink
                      to="/privacy-policy#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Privacy Policy
                    </NavHashLink>
                  </li>
                  <li>
                    <NavHashLink
                      to="/terms-and-conditions#"
                      scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
                    >
                      Terms of Use
                    </NavHashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <p className="linksHeader">Talk to us</p>
            <hr />
            <div>
              <div className="footerParagraphs">
                <ul>
                  <li>
                    <p style={{ lineHeight: "1.5" }}>
                      Ayana Renewable Power Private Limited
                      <br />
                      CIN U40106KA2017FTC101000
                      <br />
                    </p>
                  </li>
                </ul>
                <ul className="address">
                  <li className="line_h_23">
                    <a
                      href="https://www.google.com/maps?ll=13.012118,77.556306&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=2892021176651096389"
                      target="_blank"
                      rel="noreferrer"
                    >
                      S 2904, 29th floor, World Trade Center, Brigade Gateway
                      Campus, #26/1, Dr. Rajkumar Road, Rajajinagar, Bengaluru –
                      560 055, Karnataka
                    </a>
                  </li>
                </ul>
                <ul className="email">
                  <li>
                    {/* <a href="mailto:contact@ayanapower.com">
                      contact@ayanapower.com
                    </a> */}
                    Sagar 0 : &nbsp;
                    <a href="mailto:supplier@ayanapower.com">
                      supplier@ayanapower.com
                    </a>
                  </li>
                </ul>
                <ul className="contact_no">
                  <li>
                    <a href="tel:+08022076001">080-22076001</a>
                  </li>
                </ul>
                <ul className="email">
                  <li>
                    <a href="mailto:contact@ayanapower.com">
                      contact@ayanapower.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for sticky social icons */}
      <div className="sticky-container">
        <ul className="sticky">
          <li>
            <a
              href="https://www.linkedin.com/company/ayana-renewable-power/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={site_linkedin} width="32" height="32" alt="" />
            </a>
          </li>
        </ul>
      </div>
      <img className="w-100" src={footer_img} alt="" />
      <div className="row footerText align-items-center w-100 m-0">
        <div className="col-12 col-lg-8 text-lg-center">
          <p className="p-1">
            Copyright © 2023 Ayana Renewable Power Private Limited. All Rights
            Reserved
          </p>
        </div>
        {/* <div className="col-12 col-lg-4 text-lg-end">
          <p>Web Design by Honeycomb Creative Support</p>
        </div> */}
      </div>
      <CookieConsent />
      <div className="footer-bottom">
        <div className="footer-container">
          <p>AYANA RENEWABLE POWER PRIVATE LIMITED</p>
          <p>CIN: U40106KA2017FTC101000</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
