import React from "react";
import { NavHashLink } from "react-router-hash-link";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Marquee from "react-fast-marquee";

import icon1 from "../../assets/img/capacity_impacts/icon1.svg";
import icon2 from "../../assets/img/capacity_impacts/icon2.svg";
import icon3 from "../../assets/img/capacity_impacts/icon3.svg";

import ayanaBannerVideo from "../../assets/video/ayana_desktop.mp4";
import ayanaBannerVideoMobile from "../../assets/video/ayana_mobile.mp4";

import titleImgWhite from "../../assets/img/title_img_white.svg";
import section1_img from "../../assets/img/ayana_home.png";

import "./home.scss";

import HomeSlider from "../HomeSlider/HomeSlider";
import { useEffect } from "react";
import LinkedInFeed from "../LinkedInFeed/LinkedInFeed";
import NewsApiHome from "../NewsApiHome/NewsApiHome";

import GptwTag from "../../assets/img/gptw_tag.png";
import SliderImages from "../SliderImages";

export const Home = () => {
  const [showElement, setShowElement] = React.useState(false);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(true);
    });
  }, []);

  return (
    <div>
      <section
        className="video_banner p-0"
        style={{ backgroundColor: "#d0e4f1", zIndex: "-1" }}
      >
        <div className="bg-video">
          <div className="vfh">
            <video
              preload="none"
              poster=""
              playsInline=""
              className="w-100 d-none d-md-block"
              autoPlay
              muted
              loop
            >
              <source
                className="d-none d-md-block"
                src={ayanaBannerVideo}
                type="video/mp4"
              />
            </video>
            <video
              preload="none"
              poster=""
              playsInline=""
              className="w-100 d-block d-md-none"
              autoPlay
              muted
              loop
            >
              <source
                className="d-block d-md-none"
                src={ayanaBannerVideoMobile}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="overlay-content">
            <div className="row h-100">
              <div className="banner_text">
                <h1 className="headline">
                  Powering the Nation <br /> Empowering the People <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid px-0">
        <div className="row latest_news_container">
          <div className="col-md-3 col-4 col-lg-2 latest_news">Latest News</div>
          <div className="col-md-9 bb col-8 col-lg-10 bg-white p-0">
            <div className="marque_data pt-2 pt-md-3">
              <Marquee pauseOnHover speed={40} gradient={false}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex">
                      <NewsApiHome />
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img src={section1_img} alt="" className="img-fluid w-50" />
            </div>
            <div className="col-md-8 vision_mission_block ps-4">
              <div className="title text-center">
                <h4>ABOUT AYANA</h4>
              </div>
              <p>
                Ayana Renewable Power is one of India's largest and
                fastest-growing renewable energy companies. In just five years,
                our presence has expanded across India. We are working towards
                developing a 10 GWac portfolio by 2025 that can provide clean
                power to millions of people.
              </p>
              <NavHashLink to="/about#about">
                <button className="btn btn_blue mt-3">Read More </button>
              </NavHashLink>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row align-items-center text-aligin-center justify-content-center">
            <div className="title pt-4">
              <center>
                <h4 className="ayaynaPowerTitle ">Our Impact</h4>
              </center>
            </div>
          </div>
          <p
            className="text-center"
            style={{ fontSize: "8px", fontWeight: 600 }}
          >
            (*Numbers calculated as of December 2024.)
          </p>
        </div>

        <section className="container py-2">
          <div className="cardContainerHome mt-5 row">
            <div className="col-12 col-sm-4 col-md-4 col-xl-3 mb-4">
              <div className="card">
                <img className="cardImage" src={icon1} alt="" />
                <p className="cardHeading">Portfolio</p>
                <div className="cardBody">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <p
                        style={{ height: 50, fontSize: 26, fontWeight: "bold" }}
                      >
                        {isVisible ? (
                          <CountUp
                            start={0}
                            end={5}
                            delay={0}
                            duration={2.5}
                            decimals={0}
                            scrollSpyOnce
                          >
                            {({ countUpRef }) => (
                              <div>
                                ~ <span ref={countUpRef} />
                              </div>
                            )}
                          </CountUp>
                        ) : (
                          0
                        )}
                        <p>GW</p>
                      </p>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-xl-3 mb-4">
              <div className="card">
                <img className="cardImage" src={icon2} alt="" />
                <p className="cardHeading">Carbon offset</p>
                <p className="cardBody">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <div
                        style={{ height: 50, fontSize: 26, fontWeight: "bold" }}
                      >
                        {isVisible ? (
                          <CountUp
                            start={1}
                            end={9.76}
                            delay={0}
                            duration={2.5}
                            decimals={2}
                            scrollSpyOnce
                          >
                            {({ countUpRef }) => (
                              <div>
                                <span ref={countUpRef} />
                              </div>
                            )}
                          </CountUp>
                        ) : (
                          0
                        )}
                        <p>MT</p>
                      </div>
                    )}
                  </VisibilitySensor>
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-xl-3 mb-4">
              <div className="card cardThird">
                <img className="cardImage" src={icon3} alt="" />
                <p className="cardHeading">Jobs</p>
                <p className="cardBody">
                  <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                      <div
                        style={{ height: 50, fontSize: 26, fontWeight: "bold" }}
                      >
                        {isVisible ? (
                          <CountUp
                            start={1}
                            end={1234}
                            delay={0}
                            duration={2.5}
                            scrollSpyOnce
                          >
                            {({ countUpRef }) => (
                              <div>
                                ~ <span ref={countUpRef} />
                              </div>
                            )}
                          </CountUp>
                        ) : (
                          0
                        )}
                      </div>
                    )}
                  </VisibilitySensor>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="container">
          <div className="row">
            <p className="text-end pe-5">
              (*Numbers calculated till March 31st 2023)
            </p>
          </div>
        </div> */}
      </section>

      <section className="offerings">
        <center>
          <div className="title pt-4">
            <h4 className="ayaynaPowerTitle">Our Offerings</h4>
          </div>
        </center>
        <div className="container-fluid">
          <div className="row"></div>
          <div className="row images_grid">
            <div className="col-md-4 first_col pos_relative mb-3">
              <NavHashLink to="/offerings#offer">
                <video className="bg-video__content" autoPlay muted loop>
                  {/* <source
                    src="https://ayanas3.s3.amazonaws.com/solar2.c44439a49918311e2251.mp4"
                    type="video/mp4"
                  /> */}
                  <source src="/video/utility_solar.mp4" type="video/mp4" />
                </video>
                <p className="image_text pe-2">Utility-Scale Solar</p>
              </NavHashLink>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 pos_relative mb-3">
                  <NavHashLink to="/offerings#offer">
                    <video className="bg-video__content" autoPlay muted loop>
                      {/* <source
                        src="https://ayanas3.s3.amazonaws.com/windmill.c964f3f4bc49c00eda7b.mp4"
                        type="video/mp4"
                      /> */}
                      <source src="/video/utility_wind.mp4" type="video/mp4" />
                    </video>

                    <p className="image_text pe-2">Utility-Scale Wind</p>
                  </NavHashLink>
                </div>
                <div className="col-md-12 pos_relative mb-3">
                  <NavHashLink to="/offerings#offer">
                    <video className="bg-video__content" autoPlay muted loop>
                      {/* <source
                        src="https://ayanas3.s3.amazonaws.com/windmill3.e88233eafae08bc434da.mp4"
                        type="video/mp4"
                      /> */}
                      <source
                        src="/video/utility_hybrid.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <p className="image_text pe-2">
                      Utility-Scale Hybrid(Solar and Wind)
                    </p>
                  </NavHashLink>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 pos_relative mb-3">
                  <NavHashLink to="/offerings#offer">
                    <video className="bg-video__content" autoPlay muted loop>
                      {/* <source
                        src="https://ayanas3.s3.amazonaws.com/windmill2.9cca6923e32b177f6754.mp4"
                        type="video/mp4"
                      /> */}
                      <source
                        src="/video/round_the_clock.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <p className="image_text pe-2">
                      Round-the-Clock Green Power
                    </p>
                  </NavHashLink>
                </div>
                <div className="col-md-12 pos_relative">
                  <NavHashLink to="/offerings#offer">
                    <video className="bg-video__content" autoPlay muted loop>
                      {/* <source
                        src="https://ayanas3.s3.amazonaws.com/windmill4.05f9ed805fe5a22d401e.mp4"
                        type="video/mp4"
                      /> */}
                      <source
                        src="/video/green_hydrogen.mp4"
                        type="video/mp4"
                      />
                    </video>
                    <p className="image_text pe-2">Green Hydrogen</p>
                  </NavHashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="life_ayana position-relative">
        {/* gptw tag(logo) */}
        <div className="gptw_logo_box">
          <img
            src={GptwTag}
            alt="ayana obtained great place to work certificate"
            className="w-100 h-100"
          />
        </div>
        <div className="text-center">
          <img src={titleImgWhite} alt="" />
          <h4 className="lifeAyayaHeading">Life @ ayana</h4>
        </div>
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              {/* <HomeSliderImages /> */}
              <SliderImages variant="lifeAtAyana" numOfImages={1} />
            </div>
            <div className="col-md-6 col-lg-8">
              <div className="ayana_life_right">
                <div className="row"></div>

                <p className="ayana_right_big_text text-white">
                  A sneak peek into our workplace where fresh ideas are stirred
                  and enthusiasm is fueled.
                </p>
                <p className="ayana_right_small_text text-white">
                  At Ayana Renewable Power, our work culture isn't just about
                  getting the job done, it's about how we do it. We celebrate
                  each other's wins and collaborate to solve challenges.
                </p>
                <NavHashLink to="/ayana-life#">
                  <button className="btn btn_white mt-3">View </button>
                </NavHashLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="title text-center pt-4">
          <center>
            <h4>Open Job Positions</h4>
          </center>
        </div>

        <HomeSlider />

        <div className="row">
          <div className="col-8 col-sm-5 col-md-4 col-xl-4 mx-auto my-4">
            <NavHashLink to="/careers#careers" className="w-100">
              <button className="btn btn_blue w-100">View All Openings</button>
            </NavHashLink>
          </div>
        </div>
      </section>

      <section className="bg_grey">
        <div className="title text-center mb-6">
          <center>
            <h4 className="">Our LinkedIn Feed</h4>
          </center>
        </div>
        <LinkedInFeed />
      </section>
    </div>
  );
};

export default Home;
