import React, { useEffect, useState } from "react";
import NewsIcon from "../../assets/news-icon.svg";

import config from "../../config";

const BASE_URL = config.base_url;

const uploadUrl = `${BASE_URL}storage/media/`;
const getMediaApi = `${BASE_URL}api/get-media?limit=4`;

const NewsApiHome = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(getMediaApi)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  } else {
    return (
      <>
        <div className="d-flex">
          {items.data.map((data) => (
            <div className="">
              <div className="">
                <div key={data.id}>
                  <a
                    href={`${uploadUrl}${data.file}`}
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center text-dark pb-0"
                  >
                    {data.description} {" | "}
                  </a>
                  <a
                    className=""
                    href={`${uploadUrl}${data.file}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <img src={NewsIcon} alt="" width={44} height={44} /> */}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
};

export default NewsApiHome;
